app.filter('domain', function() {
  return function(input) {
  	return input ? input.replace(/http:\/\/sog.unc.dev\//,"http://dev.sog.unc.edu/") : null;
  };
});


app.filter('class', function() {
  return function(input) {
  	var className = '';
  	switch (input) {
	    // case "Microsite landing page":
		   //  className = "bulletin";
		   //  break;
		
	}
	return input.replace(/\s/g,"_");
  };
});

app.filter('escape', function() {
  return window.encodeURIComponent;
});

app.filter('trustHtml', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});

app.filter('miliseconds', function() {
  return function(input) {
        return input*1000;
    };
});

app.filter('datePluralize', function() {
  return function(input) {
      if(!input)return;
      var m = input.match(/,/g);
      return (m !== 'undefined' && m !== null)?input.match(/,/g).length:0;
    };
});

app.filter('capitalize', function() {
  return function(input) {
    // console.log(input);
    switch (input) {
      case "Centers and Services":
        break;
      default:
        input = (input) ? input.replace(/([^\W_]+[^\s-]*) */g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1);}) : '';
    }
    return input;
  };
});


app.filter('content_type_singular', function() {
  return function(input) {
    if(input)input = input.replace(/\_/g," ");
    switch (input) {
      case "faq":
        input = "FAQ";
        break;
      case "faq collection":
        input = "FAQ Collection";
        break;
      case "Microsite landing page":
        input = "Microsite";
        break;
      case "landing page":
        input = "Microsites";
        break;
      case "Basic page":
        input = "Page";
        break;
      case "course offering":
        input = "Upcoming Courses";
        break;
      case "webinar event":
        input = "Webinars";
        break;
    }

  return input;
  };
});

app.filter('content_type_plural', function() {
  return function(input) {
    switch (input) {
      case "centers_and_services":
        input = "Centers and Services";
        break;
      case "content_org":
        input = "Content Organizations";
        break;
      case "faq":
        input = "FAQs";
        break;
      case "faq_collection":
        input = "FAQ Collections";
        break;
      case "landing_page":
        input = "Microsites";
        break;
      case "basic_page":
        input = "Pages";
        break;
      case "course_offering":
        input = "Upcoming Courses";
        break;
      case "legal_summary":
        input = "Legal Summaries";
        break;
      case "legal_summ_coll":
        input = "Legal Summary Collections";
        break;
      case "webinar_series":
        input = "Webinar Series";
        break;
      case "webinar_event":
        input = "Webinars";
        break;
      default:
        input = input.replace(/\_/g," ");
        input += (input.slice(-1) !== 's')?'s':'';
    }

  return input;
  };
});

app.filter('unique', function() {
    return function(input, key) {
        if(typeof input == "undefined")return;

        var unique = {};
        var uniqueList = [];
        for(var i = 0; i < input.length; i++){
          // console.log('input[i]: '+input[i]);
            if(typeof unique[input[i][key]] == "undefined"){
                unique[input[i][key]] = "";
                uniqueList.push(input[i]);
            }
        }
        // console.log(uniqueList);
        return uniqueList;
    };
});


app.filter('standardizeName',function(){
  return function(input) {
      input = input.replace(/\s/g,"_").toLowerCase();
  return input;
  };
});


var content_types = '{'+
          '"announcement":{"label":"Announcement","label_plural":"Announcements","path":"announcements"},'+
          '"article":{"label":"Article","label_plural":"Articles","path":"articles"},'+
          '"page":{"label":"Page","label_plural":"Pages","path":"pages"},'+
          '"blog":{"label":"Blog","label_plural":"Blogs","path":"blogs"},'+
          '"blog_post":{"label":"Blog Post","label_plural":"Blog Posts","path":"blog-posts"},'+
          '"book":{"label":"Book","label_plural":"Books","path":"books"},'+
          '"book_chapter":{"label":"Book Chapter","label_plural":"Book Chapters","path":"book-chapters"},'+
          '"bulletin":{"label":"Bulletin","label_plural":"Bulletins","path":"bulletins"},'+
          '"content_org":{"label":"Content Organization","label_plural":"Content Organizations","path":"content-organizations"},'+
          '"centers_and_services":{"label":"Centers and Services","label_plural":"Centers and Services","path":"centers-and-services"},'+
          '"course":{"label":"Course","label_plural":"Courses","path":"courses"},'+
          '"course_list":{"label":"Course List","label_plural":"Course Lists","path":"course-lists"},'+
          '"course_material":{"label":"Course Material","label_plural":"Course Materials","path":"course-materials"},'+
          '"course_offering":{"label":"Course Offering","label_plural":"Course Offerings","path":"course-offerings"},'+
          '"faq":{"label":"FAQ","label_plural":"FAQs","path":"faqs"},'+
          '"faq_collection":{"label":"FAQ Collection","label_plural":"FAQ Collections","path":"faq-collections"},'+
          '"legal_summary":{"label":"Legal Summary","label_plural":"Legal Summaries","path":"legal-summaries"},'+
          '"legal_summ_coll":{"label":"Legal Summary Collection","label_plural":"Legal Summary Collections","path":"legal-summary-collections"},'+
          '"listserv":{"label":"Listserv","label_plural":"Listservs","path":"listservs"},'+
          '"media":{"label":"Media","label_plural":"Media","path":"media"},'+
          '"landing_page":{"label":"Microsite","label_plural":"Microsites","path":"microsites"},'+
          '"news":{"label":"News","label_plural":"News","path":"news"},'+
          '"online_module":{"label":"Online Module","label_plural":"Online Modules","path":"online-modules"},'+
          '"profile":{"label":"Profile","label_plural":"Profiles","path":"profiles"},'+
          '"report":{"label":"Report","label_plural":"Reports","path":"reports"},'+
          '"publication_series":{"label":"Resource Series","label_plural":"Resource Series","path":"resource-series"},'+
          '"tool":{"label":"Tool","label_plural":"Tools","path":"tools"},'+
          '"warehouse_document":{"label":"Warehouse Document","label_plural":"Warehouse Documents","path":"warehouse-documents"},'+
          '"webform":{"label":"Webform","label_plural":"Webforms","path":"webforms"},'+
          '"webinar_event":{"label":"Webinar","label_plural":"Webinars","path":"webinars"},'+
          '"webinar_series":{"label":"Webinar series","label_plural":"Webinar Series","path":"webinar-series"}'+
        '}';

var obj = JSON.parse(content_types);

app.filter('content_types', function() {
return function(input, type){
  var output;
  
  if(type.input === 'machine_name' && obj[input]) {
    output = obj[input][type.output];
  
  }else if(type.input === 'path'){
    angular.forEach(obj,function(value,key){

      if(input === value[type.input]){
        output = (type.output === 'machine_name')?key:value[type.input];
        return;
      }

    });

  }

  return output;
  };
});
