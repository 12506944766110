(function() {

  var app = angular.module('sog');

  var InfoService = function($http, $q) {
    this.$http = $http;
    this.$q = $q;
  };

  InfoService.$inject = ['$http', '$q'];

  InfoService.prototype.getInfo = function(filter,params) {
    // console.log('/data/'+filter);
    return this.$http.get('/data/'+filter,params);
  };

  InfoService.prototype.getNode = function(uri) {
    // console.log(uri);
    return this.$http.get(uri);
  };

  InfoService.prototype.getProfile = function(id) {
    // console.log('/data/faculty-and-staff/'+id);
    return this.$http.get('/data/faculty-and-staff/'+id);
  };
  
  app.service('infoService', InfoService);

}());