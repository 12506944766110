// 2015-09-17 bradley cummins
// loadFilterValues() and updateFilterValues() used with nodeController on profile pages.
// Originally filter selections were collected w jQuery and stored in localStorage for all 
// api generated views.  Shortly after launch we switched to an AngularJS path approach so 
// the state of the app could be shared via url (copy/paste, bookmark google webwasters sitemap, 
// etc).  

// When you can I would recommend changing nodeController to the AngularJS path approach as well.
// For example, http://sog.unc.edu/about/faculty-and-staff/frayda-s-bluestein#courses/online-modules/search/local-government

(function() {

  var UrlService = function($location,$filter){

    UrlService.prototype.getPage = function(){
      var d = window.location.pathname.split('/');
      var page = (d[1]==='about' || d[1]==='giving')?d[2]:d[1];
      return (page === '')?'home':page;
    };
 
    UrlService.prototype.loadFilterValues = function(filterData){
      var _this = this;
      var page = _this.getPage();

      var key,value,uri,base,select,mValue,valueKey,obj,v,u;
      if($(".filter.select").length > 0){ 
        $.each($(".filter.select"),function(){
          mValue = [];
          select = $(this).find('select');
          base = select.attr('data-base');
          
          uri = $(this).find('.filter-option>span').attr('data-field');
          uri = (!uri)?select.attr('name'):uri;
          uri = uri.replace(/\[\]/g,'');

          if(uri.split(',').length > 1){
            //select consists of more than one vocabulary
            v = uri.split(',');
            value = null;
            for(u in v){
              //select not multiple only select one value -> need to troubleshoot in case role and topic have same tid
              key = page+(($('#node').length > 0 && base)?'.'+base:'')+".filter."+v[u];
              value = (!value)?localStorage.getItem(key):value;
              // console.log(key+' : '+localStorage.getItem(key));
            }
          }else{
            key = page+(($('#node').length > 0 && base)?'.'+base:'')+".filter."+uri;
            value = localStorage.getItem(key);
            // console.log('solo: '+key+' : '+value);

            // if(uri === 'sort_by' && val[i] === 'score' && !localStorage.getItem(page+'.filter.search'))val[i]=$('#view').attr('data-sort-by');console.log(val[i]);
          }
          
          if(select.attr('multiple') && filterData[uri] && value !== null){
            value = value.split(',');
            for(i=0;i<value.length;i++){
              // find position of tid in filterData stack and assign to select
              // console.log(filterData[uri]);
              valueKey = (select.attr('data-option-value-key'))?select.attr('data-option-value-key'):'value';
              pos = findValue(filterData[uri],valueKey,value[i]);
              if(pos !== false){mValue[mValue.length] = pos;}
            }
            value = mValue;
          }else if(select.attr('multiple')){
            return;
          }
          
          // not work
          // view multiple filled by data-options

          // work
          //view single filled by data-options

          if(value && select.attr('data-options')){ 

            obj = select.attr('data-options').split(',');
            // Used w/ Angular 1.3.18
            // $.when(_this.findValue(obj,false,value))
            //  .done(function(value){select.selectpicker('val', 'string:'+value);console.log('string:'+value);});
            select.selectpicker('val', 'string:'+value); //Used w/ Angular 1.4.4
          }else{
            if(value){
              select.selectpicker('val', value);
              // console.log('load '+key+' : '+value+' into '+select.attr('data-options'));
            }
          }
        });

        if( base === 'blogs' && $('[name="content_type[]"]').val() === '1' )$('.filter.select.blogs').removeClass('hidden');
        // console.log($('[name="content_type[]"]').val() );
      }
      
      if($(".filter input[type=text]").length > 0){ 
        $.each($(".filter input[type=text]"),function(){
          if(typeof $(this).attr('name') === typeof undefined)return;//ignore bootstrap-select search box in select filters
          base = $(this).attr('data-base');
          uri = $(this).attr('name');
          // key = page+".filter."+uri;
          key = page+((base && $('#node').length > 0)?'.'+base:'')+'.filter.'+uri;
          value = localStorage.getItem(key);

          if(value){$(this).val(localStorage.getItem(key));}
        });
      }
    };

    UrlService.prototype.updateFilterValues = function($scope){

      $('.no-results').addClass('hidden');
      var url,uri,value,select,mValue,valueKey,key,key2,change,storedVal,input,u,p,m;
      var filter=[];
      var page = this.getPage();
      if(page === '')return;

      // console.log($scope);
      var filterData = ($scope.viewData)?$scope.viewData.filter:$scope.node.filter;

      if($(".filter.select").length > 0){ 
        $.each($(".filter.select"),function(){
          select = $(this).find('select');
          base = select.attr('data-base');

          uri = $(this).find('.filter-option>span').attr('data-field');
          uri = (!uri)?select.attr('name'):uri;
          uri = uri.replace(/\[\]/g,'');
          value = select.val();

          mValue = [];
          key = page+(($('#node').length > 0 && base)?'.'+base:'')+'.filter.'+uri;
          storedVal = localStorage.getItem(key);
          // console.log(key+' : '+value+' : '+storedVal);

          if(!value || value === ''){
            if(uri.split(',').length > 1){
              uri = uri.split(',');
              for(u in uri){
                // console.log('a: '+uri[u]);
                key = page+(($('#node').length > 0 && base)?'.'+base:'')+'.filter.'+uri[u];
                localStorage.removeItem(key);
              }
            }else{
              if(!$scope.viewData && storedVal && storedVal !== ''){
                change = base;
                // console.log('key: '+key+', storedVal: '+storedVal);
              }
              localStorage.removeItem(key);
            }
            return;
          }
          
          if(filterData && select.attr('multiple')){
            // console.log(filterData);
            valueKey = (select.attr('data-option-value-key'))?select.attr('data-option-value-key'):'value';
            for(i=0;i<value.length;i++){
              mValue[mValue.length] = filterData[uri][value[i]][valueKey];
            }
            if(!$scope.viewData && storedVal !== mValue.join(',')){change = base;}
            localStorage.setItem(key, mValue.join(','));
          }else if(select.attr('data-options')){
            // console.log('set '+page+'.'+base+'.filter.'+uri+' to '+select.attr('data-options').split(',')[value]);
            if(!$scope.viewData && storedVal !== select.attr('data-options').split(',')[value]){change = base;} //important for profile tabs
            // localStorage.setItem(key, select.attr('data-options').split(',')[value]);  Used w/ Angular 1.3.18
            localStorage.setItem(key, value.replace(/string:/,'')); // Used w/ Angular 1.4.4
          }else{
            if(!$scope.viewData && storedVal !== value){change = base;}
            u = $(this).find('[data-original-index="0"]').find('a').attr('data-normalized-text');
            p = /data-field=\'(.*?)\'/g;
            m = p.exec(u);
            v = m[1].replace(/\[\]/g,'').split(',');
            for(u in v){
              if(v[u] !== uri){
                //select not multiple so delete localStorage values from other vocabularies in the this filter
                localStorage.removeItem(page+(($('#node').length > 0 && base)?'.'+base:'')+'.filter.'+v[u]);
              }
            }
            localStorage.setItem(key, value);
          }

        });
      }

      if($(".filter.text").length > 0){
        $.each($(".filter.text"),function(){      
          input = $(this).find('input[type=text]');
          if(typeof input.attr('name') === typeof undefined)return;//ignore bootstrap-select search box in select filters
          base = input.attr('data-base');
          uri = input.attr('name');
          value = input.val();
          key = page+((base && $('#node').length > 0)?'.'+base:'')+'.filter.'+uri;
          // console.log('key: '+key+'\nvalue: '+value);
          if(value === ''){
            if(localStorage.getItem(key) !== null)change = base;
            localStorage.removeItem(key);
            return;
          }else{
            change = base;
            localStorage.setItem(key, value);
            localStorage.setItem(page+'.filter.sort_by','score');
            localStorage.setItem(page+'.filter.sort_order','desc');
          }
        });
      }
      if($scope.viewData){
        $scope.viewData.nextPage(0);
      }else{
        // console.log('reload '+change);
        $scope.node.reloadView($scope.reloadTab);
      }
      return;
    };

    UrlService.prototype.generateUrls = function($scope){
      var page = this.getPage();
      var filters = [];
      var uri = [];
      var name,value,s;
      var ct = $filter('content_types');
      var path = [];
      var search = [];
      var sort = [];
      var keywords = false;

      $.each($("[ng-model^='filter.']"),function(i,filter){
        key = $(this).attr('ng-model').substring(7);
        value = $scope.filter[key];
        
        if(page === 'search') localStorage.setItem('search.filter.search',value);

        if(typeof value === 'undefined' || value === ''){

        }else if(key === 'content_type'){
          path[path.length] = ct(value,{'input':'machine_name','output':'path'});
        }else if(key === 'search'){
            search[search.length] = key;
            search[search.length] = value;
        }else if(key === 'sort_by'){
          if(value !== $('#view').attr('data-sort-by')){
            switch (value){
              case "field_first_name_value_1":
                s = "first-name";
                break;
              case "field_offering_date_value":
                s = "offering-date";
                break;
              case "changed":
                s = "last-updated";
                break;
              case "created":
                s = "published";
                break;
              case "field_keywords":
                s = "fields-of-expertise";
                break;
              default:
                s = value;
            }

            sort[sort.length] = 'by';
            sort[sort.length] = s;
            if(value === 'field_keywords'){ 
              keywords = true;
              return;
            }
          }
        }else if(key === 'sort_order'){
          if(value !== $('#view').attr('data-sort-order')){
            sort[sort.length] = 'order';
            sort[sort.length] = value;
          }
        }else{
          if(!$scope.options[key][value]){
            return;
          }else if(key === 'field_profile_types'){
            path[path.length] = 'types';
            path[path.length] = value;
            path[path.length] = $scope.options[key][value].value.toLowerCase().replace(/\s/g,'-');
          }else if(key === 'field_group'){
            path[path.length] = 'groups';
            path[path.length] = value;
            path[path.length] = $scope.options[key][value].value.toLowerCase().replace(/\s/g,'-');
          }else if(key === 'roles'){
            path[path.length] = 'public-officials';
            path[path.length] = value;
            path[path.length] = $scope.options[key][value].value.toLowerCase().replace(/\s/g,'-');
          }else if(key === 'topics'){
            path[path.length] = key;
            path[path.length] = value;
            path[path.length] = $scope.options[key][value].value.toLowerCase().replace(/\s/g,'-');
          }else if(key === 'field_author'){
            path[path.length] = 'authors';
            path[path.length] = value;
            path[path.length] = $scope.options[key][value].value.toLowerCase().replace(/\s/g,'-');
          }else if(key === 'field_blog_reference'){
            path[path.length] = 'blogs';
            path[path.length] = value;
            path[path.length] = $scope.options[key][value].value.toLowerCase().replace(/\s/g,'-');
          }
        }

        if(typeof value === 'undefined' || value === null || value === '')return;

        if($('#view').find('[ng-model]').length === 0)return;

        //roles and topics select filters consist of two taxonomies so need to get appropriate drupal contextual key for selection
        name = (!$('[ng-model="filter.'+key+'"]').attr('name'))?$scope.options[key][value].type:$('[ng-model="filter.'+key+'"]').attr('name');
        
        // 2015.08.20 join all search strings with OR per Todd's request 
        if(page !== 'search-test' )value = value.replace(/\s/g,'+OR+');
        
        if(key === 'content_type' && value === 'landing_page'){
          filters[filters.length] = 'content_type[]=landing_page';
          filters[filters.length] = 'field_type_value[]=Microsites';
          return;
        }else if(key === 'content_type' && value === 'centers_and_services'){
          filters[filters.length] = 'content_type[]=landing_page';
          filters[filters.length] = 'field_type_value[]=Centers';
          filters[filters.length] = 'field_type_value[]=Services';
          return;
        }

        filters[filters.length] = name+'='+value;
      });
      
      var subset = [];
      if($scope.viewData.subset.active){
        // default
        // if($scope.viewData.subset.active === 'New Publications'){ subset[subset.length] = 'featured'; subset[subset.length] = 'new-publications';}
        if($scope.viewData.subset.active === 'Forthcoming Titles'){ subset[subset.length] = 'featured'; subset[subset.length] = 'forthcoming-titles';}
        if($scope.viewData.subset.active === 'Updates and Supplements'){ subset[subset.length] = 'featured'; subset[subset.length] = 'updates-and-supplements';}
      }
      
      var p = ($scope.filter.sort_by !== 'score' )?path.concat(search).concat(sort).concat(subset).join('/'):path.concat(search).concat(subset).join('/');
      uri.path = p;
      uri.api = [];
      uri.api.data = '/data/'+page+((keywords)?'/keywords':'')+'?'+filters.join('&');
      if(!keywords) uri.api.results = '/data/'+page+'/results?'+filters.join('&');
      if($('#subset').length > 0) uri.api.subset = '/data/'+page+'/subset';
      
      return uri;

    };
  
  };
  
  var app = angular.module('sog');
  app.service('urlService', ['$location','$filter',UrlService]);

}());