var trackerCtrl = function($scope, $http, $filter, $q) {
	var intro = 'Add a comment to this issue here:';
	var divider = '----------------';

	$scope.issues = [];
	$scope.i = null;
	$scope.team = ['Bradley','Dan','Stephen','Stefanie','Group'];
	$scope.userFilter = null;
	$scope.assignedToFilter = null;

    $(document).ready(function(){
		if(tracker){
			getIssues();
		}else{
			$('.release').hide();
			$('.known').hide();
			$('#issueTracker').find('.report').off().removeAttr('ng-click').on('click',function(){window.open('https://sog-helpdesk.sog.unc.edu/');});
			$('#issueTracker').css({'display':'block'});
		}

		// $('[data-toggle="popover"]').popover();
		$('#issueTracker').find('.title').find('.release').off().on('click',function(){location.href='/resources/microsites/sog-website-release-notes';});
		$('#issueTracker').find('.title').find('.known').off().on('click',function(){location.href='/resources/microsites/sog-website-known-issues';});
	});

	$scope.open = function(){
		$('#issueTracker').find('.body').slideDown(1000);	
		$('#issueTracker').find('.body').find('textarea').val('');
		$('#issueTracker').find('.body').find('textarea').focus();
		$('#issueTracker').find('.closeBtn').removeClass('hidden');
	};

	$scope.close = function(){
		$('#issueTracker').find('.body').find('textarea').val('');
		$('#issueTracker').find('.body').slideUp('slow');
		$('#issueTracker').find('.closeBtn').addClass('hidden');	
	};

	$scope.cancel = function(){
		$scope.i = null;
		$scope.msg = '';
		$('.issues').find('.issue').removeClass('active');
	};

	$scope.results = function(){
		if($scope.issues.length !== 0){
			$('#issueTracker').find('.log>.issues').slideToggle('slow');
			$('#issueTracker').find('.filters').toggleClass('hidden');
		}
	};

	$scope.viewAll = function(){
		// not complete -> sql in module
		// $http.post('/issue-tracker', {'digest':'1'}
		// ).success(function(data, status, headers, config) {
		// 	$scope.digest.items = data;

		// }).error(function(data, status, headers, config) {
		// 	console.log('fail');
		// });
	};

	$scope.loadMsg = function(issue){
		for(var i=0;i<$scope.issues.length;i++){
			if($scope.issues[i].id === issue.id)break;
		}
		$('.issues').find('.issue').removeClass('active');
		$('.issues').find('[data-mid="'+issue.id+'"]').addClass('active');

		var m = issue.messages;
		var log = intro+'\n\n\n\n';
		
		angular.forEach(m,function(value,key){
			log += divider+'\n';
			log += 'Previous message received on ';
			log += $filter('date')(value.timestamp*1000, "yyyy.MM.dd hh:mm a", "-0500")+'\n';
			log += value.msg+'\n\n\n';
		});
		log += divider+'\n';
		log += "Originated from "+issue.url+'\n';
		$scope.i = i;
		$scope.msg = log;
		
		$scope.assignedTo = issue.assignedTo;
		// console.log($scope.assignedTo +' = '+issue.assignedTo);
		setTimeout(function(){$('.selectpicker').selectpicker('refresh');},100);
		

		$http.post('/issue-tracker', {'id':issue.id,'status':'2'}
		).success(function(data, status, headers, config) {
			$scope.issues[i].status = '2';
		}).error(function(data, status, headers, config) {
			console.log('fail');
		});
	};

	$scope.assign = function(){
		var assignedTo = ($scope.assignedTo)?$scope.assignedTo:"";
		$http.post('/issue-tracker', {'id':$scope.issues[$scope.i].id,'assignedTo':assignedTo}
		).success(function(data, status, headers, config) {			
			$scope.issues[$scope.i].assignedTo = $scope.assignedTo;
		}).error(function(data, status, headers, config) {
			console.log('fail');
		});
	};

	$scope.from = function(item){
		return (!$scope.userFilter || ($scope.userFilter && $scope.userFilter.uid === item.uid))?item:null; 
	};

	$scope.assigned = function(item){
		return (!$scope.assignedToFilter || ($scope.assignedToFilter === item.assignedTo))?item:null; 
	};

	$scope.delete = function(issue){
		for(var i=0;i<$scope.issues.length;i++){
			if($scope.issues[i].id === issue.id)break;
		}
		var txt;
		var r = confirm("Are you sure you would like to delete this issue?");
		if (!r)return;
		$http.post('/issue-tracker', {'id':$scope.issues[i].id,'status':'0'}
		).success(function(data, status, headers, config) {
			$scope.issues.splice(i,1);
			$scope.i = null;
			$scope.msg = '';
		}).error(function(data, status, headers, config) {
			console.log('failed delete');
		});
	};

	$scope.hide = function(issue){
		for(var i=0;i<$scope.issues.length;i++){
			if($scope.issues[i].id === issue.id)break;
		}
		var txt;
		var r = confirm("Are you sure you would like to hide this issue?  The issuer will still see this issue in their issue tracker.  If the issuer replies this issue will show up again in your administrative issue tracker.");
		if (!r)return;
		$http.post('/issue-tracker', {'id':$scope.issues[i].id,'status':'3'}
		).success(function(data, status, headers, config) {
			$scope.issues.splice(i,1);
			$scope.i = null;
			$scope.msg = '';
		}).error(function(data, status, headers, config) {
			console.log('failed hide');
		});
	};

	$scope.submit = function(){
		if ($scope.msg){
			var msg = ($scope.i !== null)?$scope.msg.split(divider).shift().split('\n').slice(1,Number.MAX_VALUE).join(''):$scope.msg;
			if(!msg)return;
			var n = ($scope.i === null)?true:false;
			var entry = {};
			entry.mid = ($scope.i !== null)?$scope.issues[$scope.i].id:'';
	    	entry.msg = msg;
	    	entry.url = location.href;
	    	// console.log(entry);
			$http.post('/issue-tracker', entry
			).success(function(data, status, headers, config) {
				// console.log(data);
				if($scope.i === null){
					
					$scope.issues.unshift(data[0]);
					$scope.i = 0;
					$scope.issues[$scope.i].messages=[];
				}
				entry.timestamp = data[0].timestamp;
				entry.url = data[0].url;
				$scope.issues[$scope.i].messages.unshift(entry);
				$('#issueTracker').find('.issueStatus').text('Thank you for your input.');
				if(!n){
					$scope.loadMsg($scope.issues[$scope.i]);
				}else{
					$scope.cancel();
				}
				$("#issueTracker").find('.issueStatus').text("Submission received.  Thank you for your input.");
				$("#issueTracker").find('.issueStatus').slideToggle();
				setTimeout(
					function(){
						$("#issueTracker").find('.issueStatus').slideToggle(function(){
							$("#issueTracker").find('.issueStatus').text("");
						});
					},
				5000);
			}).error(function(data, status, headers, config) {
				console.log('fail');
			});
		}
	};

	function getIssues(){
		$http.get('/issue-tracker'
		).success(function(data, status, headers, config) {
			if(data.length === 0){
				$('#issueTracker').css({'display':'block'});
			}else{
				var promise = [];
				angular.forEach(data,function(value,key){
					promise.push(getMessages(value.id));
				});
				$q.all(promise).then(function(msgs){
					var i=0;
					angular.forEach(data,function(value,key){
						value.messages = msgs[i];
						i+=1;
					});
					$scope.issues = data;
					setTimeout(function(){
					   	$('.selectpicker').selectpicker('refresh');
					   	$('#issueTracker').css({'display':'block'});
					},100);
				});
			}
		}).error(function(data, status, headers, config) {
			console.log('fail');
		});
	}

	function getMessages(mid){
		return $q(function(resolve,reject){
			$http.post('/issue-tracker',{'mid':mid}
			).success(function(data, status, headers, config) {
				resolve(data);
			}).error(function(data, status, headers, config) {
				reject('promise failed');
			});
		});
	}
};

app.controller('IssueTrackerController', trackerCtrl);