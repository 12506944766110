// jQuery('.navbar-offcanvas').addClass('in');
var breakpoint = 992;
var bannerPause = true;
var bannerInterval = 20;
var socialShareDisabled = false;
var rotateBanner;
var banerInt = 0;
var navbarLg = 140;
var navbarSm = 90;
var endLogoScale = 50;
var startLogoScale = 0;
var logoHeightLg = 75;
var logoHeightSm = 48;
var logoMarginTopLg = 20;
var logoMarginTopSm = 6;
var menuFade = false;
var endMenuFade = 20;
var startMenuFade = 0;
var menuFontLg = 16;
var menuFontSm = 16*0.9;
var searchTopLg = -65;
var searchTopSm = -30;
var inArr = {};

var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = "Don't call this twice without a uniqueId";
    }
    if (timers[uniqueId]) {
      clearTimeout (timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

var m = new Date().getTime();

if(localStorage.getItem('home.banner')){
	$('#sog-banner').css({display:'none'});
	// $("#banner-toggle.out").trigger('click');
}

jQuery(document).ready(function(){

	contentBkgd();
	scrollParam();
	etcDisplay();
	if(socialShareDisabled)socialShareDisable();

	if(jQuery("#sog-banner").length > 0){
		var d = jQuery.Deferred(), 
		p=d.promise();
		p.then(bannerInit).then(localSettings);
		d.resolve();
	}
	
	menuControls();
	// menuDataControls();
	hideDetails();

	if($('#tabs-menu').length > 0){
		$('#tabs-menu').tabCollapse({
			tabsClass: 'hidden-sm hidden-xs',
	    	accordionClass: 'visible-sm visible-xs'
		});
	}
	// jQuery('.navbar-offcanvas').addClass('in');
	// jQuery('.navbar-offcanvas').addClass('canvas-slid');
	// jQuery('.row-offcanvas-right').addClass('active');
	
	addScrollToAnchors();
	scrollToTopBtn();
	setTimeout(function(){fixedNavbar();},500);//trigger after admin_menu
	activeTabs();
    window.onscroll = function() {
    	fixedNavbar();
    	menuControls();
    	scrollToTopBtn();
    };
    addThemeFormElements();	    

    $('[data-lightbox="tour-website"]').on( "click", function(){lightboxOptions(); });
});

jQuery(window).bind('resize orientationchange', function() {
	// document.body.clientWidth = document.body.clientWidth;

	waitForFinalEvent(function(){
    	contentBkgd();
    	scrollParam();
    	fixedNavbar();
    	menuControls();
    	closeMenu();
    	if(jQuery("#sog-banner").length > 0){bannerControlsOn();}
		// if(jQuery('.entry-content > #social').size() > 0){positionSocial();}
    	
    	// jQuery('.nav-collapse').collapse('hide');
    	jQuery('.navbar-offcanvas').removeClass('in');
		jQuery('#navbar-spacer').hide();
    }, 500, m);
});

function contentBkgd(){
	if(jQuery("#content-wrap").length === 0){return;}
	var contentMin = jQuery(window).height()-jQuery("#content-wrap").offset().top-jQuery("body >footer").height()-20;
	if(jQuery("#content-wrap").height()<contentMin){
   		// jQuery("#content-wrap").height(contentMin);
   		jQuery("#content-wrap").css({"min-height":contentMin});
   	}
   	// console.log(contentMin);
   	var menuHeight = jQuery(".navbar-container").height();
   	if(jQuery(".navbar").css("position") === "fixed" && menuHeight > jQuery(window).height()){
		var h = jQuery(window).height()-jQuery(".navbar").height(); 
		// jQuery("#nav").css({"overflow":"scroll","height":h});
   	}else{
   		// jQuery("#nav").css({"overflow":"auto","height":"auto"});
   	}
   	// console.log(menuHeight+" = "+jQuery(".navbar-offcanvas").height()+" + "+jQuery(".navbar").height());
}

function scrollParam(){
	//save scroll parameters
	var height = jQuery(document).height();
	var footer = jQuery("body > footer").height();
	
	var pad = jQuery("#content-wrap").outerHeight(true) - jQuery("#content").outerHeight(true); 

	// jQuery("#content").css({"padding-bottom":pad});
	
	jQuery(".scrollable").each(function(){

		var h = height+jQuery("#"+this.id+"-read-more").outerHeight(true);
		
		var top = jQuery(this).offset().top;
		top -= (jQuery(".navbar").css("position") == "fixed")?jQuery(".navbar").height():0;
		var r = h-top;
		var spacerHeight = (jQuery(window).height()>r)?jQuery(window).height()-r:0;

		// var h2 = jQuery(this).outerHeight(true)+jQuery("#"+this.id+"-read-more").innerHeight();		

		jQuery("#"+this.id+" #scroll").text(top);
		jQuery("#"+this.id+" #spacer").text(spacerHeight);
		
		// jQuery(this).css({border:"1px dashed green"});

		// if(jQuery(window).height()>r){
			// jQuery("#print-script").html(
			// 	jQuery("#print-script").html()+"<br/><br/>"+
			// 	this.id+"<br/>"+
			// 	jQuery("#content").outerHeight(true)
			// 	// jQuery(window).height()+" - "+r +"("+height+" + "+jQuery("#"+this.id+"-read-more").height()+" - "+top+") = "+spacerHeight
			// 	// jQuery(this).outerHeight(true)+" + "+jQuery("#"+this.id+"-read-more").innerHeight()+" = "+h2
			// );
			// jQuery("#"+this.id+" #spacer").text(300);
		// }
	});
}

function addScrollToAnchors(){
	jQuery('a[href^="#"]').bind('click.smoothscroll',function (e) {
        e.preventDefault();
        var target = this.hash;
        jQuerytarget = jQuery(target);
	    jQuery("html, body").animate({ scrollTop: jQuerytarget.offset().top - navbarSm - 4 }, 600);
    });
}

function etcDisplay(){
	jQuery('.etc').on("click",function(){
		var c = jQuery(this).attr("class").split(" ");
		// jQuery("#"+c[0]+" .etc-icon").slideToggle();
		jQuery("#"+c[0]+" .etc-icon").toggleClass("hide");
		// jQuery(".social .hide")
		
		return false;
	});
}

function renderCaptcha(){
	var widgetId1;
	var onloadCallback = function() {
	    widgetId1 = grecaptcha.render('recaptcha', {
	      'sitekey' : '6LcmVf8SAAAAAIMHDAfzhd4xuShnFw1T1rQBNizD',
	      'theme': 'light',
	    });

	};
}

function bannerInit(){
	
	var deferred = $.Deferred();

	// setTimeout(function(){
		// console.log('waiting');
		// console.log("bannerInit: ",deferred.state());
		var img = [];
		
		$("#load-img .sog-banner").each(function(){img.push($(this).attr("src"));});

		rotateBanner = (!bannerPause)?setInterval(function(){

		  	var nextImage = (banerInt < img.length-1)?img[banerInt+1]:img[0];
		  	banerInt += 1;
		  	
	    	$("#sog-banner").fadeOut("slow",function(){
	           	$(this).css("background-image","url("+nextImage+")").fadeIn("slow");
	        });

	    }, bannerInterval*1000):null;
		
		$("#banner-controls").prependTo($(".sog-body"));
	    
	    bannerControlsOn();

	    deferred.resolve("from bannerInit");

	// },2000);
	// console.log('bannerInit');
	return deferred.promise();
}

function bannerControlsOn(){
	var navbarHeightMin = (breakpoint > $(window).width())?25:80;
    
    $("#banner-skip").off().on("click",function(){
		var skipBanner = $(".sog-body").offset().top - 10 - ((breakpoint > $(window).width())?60:80)-((jQuery("#admin-menu").length > 0)?jQuery("#admin-menu").height():0);
    	$("html, body").animate({ scrollTop:skipBanner}, 800);
    	// console.log("skipBanner: "+skipBanner+" - "+((jQuery("#admin-menu").length > 0)?jQuery("#admin-menu").height():0)+" - "+jQuery("#admin-menu").height());
    });

    // $("#banner-info").off().on("click",function(){
    // 	console.log("launch light box with caption msg");
    // });
	
	var ln = 800;

    $("#banner-toggle.out").off().on("click",function(){
    	// console.log("hide");
    	clearInterval(rotateBanner);
		$(function () {
		    $("#sog-banner").slideUp({ duration: ln, queue: false});
			$("html, body").animate({scrollTop:navbarHeightMin}, { duration: ln, queue: false });
		    $("#banner-info").fadeOut({ duration: ln, queue: false});
		    $("#banner-skip").fadeOut({ duration: ln, queue: false, 
	    		complete:
			    	function(){
				    	$("#banner-toggle").removeClass("out").addClass("in");
						bannerControlsOn();
						scrollToTopBtn();
						localStorage.setItem('home.banner',0);
						// console.log('close banner');
					}
			});
		});

    });

    $("#banner-toggle.in").off().on("click",function(){
    	// console.log("show");
		$(function () {
		    $("#sog-banner").slideDown({ duration: ln, queue: false });
		    $("html, body").animate({scrollTop:0}, { duration: ln, queue: false });
		    $("#banner-info").fadeIn({ duration: ln, queue: false});
		    $("#banner-skip").fadeIn({ duration: ln, queue: false, 
		    		complete:
				    	function(){
							$("#banner-skip").removeClass("hidden");
							$("#banner-toggle").removeClass("in").addClass("out");
							bannerInit();
							bannerControlsOn();
							scrollToTopBtn();
							localStorage.removeItem('home.banner');
						}
				});
		});
    });
}

function localSettings(){
	if(localStorage.getItem('home.banner')){
		// console.log('click');
    	$("#banner-toggle.out").trigger('click');
    }else{
    	// console.log('show');
		$('#sog-banner').css({display:''});
		$('#banner-info').css({display:''});
	}
	return;
}

function scrollToTopBtn(){
	var navbarHeightMin = ($("#sog-banner").length > 0 && $("#sog-banner").css("display") == "none")?((breakpoint > $(window).width())?25:80):0;
	var top = ($("#sog-banner").length > 0 && $("#sog-banner").css("display") == "none")?$(".sog-body").offset().top - $(".navbar").height():0;

	jQuery('#toTop').off().on("click",function(){
		jQuery("html, body").animate({ scrollTop: navbarHeightMin }, 600);
		return false;
	});

	if(200 > jQuery(this).scrollTop() || ($("#sog-banner").length > 0 && $("#sog-banner").css("display") == "none" && top >= jQuery(this).scrollTop())){
		jQuery('#toTop').fadeOut();
	}else{
		jQuery('#toTop').fadeIn();
	}
}

function fixedNavbar(){
	var top;
	var menuTop;
	if( breakpoint > $(window).width()){
		$(".navbar-container").height(60);
		top = ($('#admin-menu').length > 0)?$("#admin-menu").height():0;
		// $(".navbar").css({"top":top});
		
		// if($('.admin-menu').length > 0){
		// 	$("html body").css({"margin-top":$("#admin-menu").height()+" !important"});
		// 	console.log($("#admin-menu").height());
		// }

		menuTop = ($('#admin-menu').length > 0)?$("#admin-menu").height()+$(".navbar-container").height()+2:$(".navbar-container").height()+2;
		$(".navbar-offcanvas").css({"top":menuTop});
		
		// $("#main-menu-row").css({"top":menuTop});
		$(".navbar-logo").css({marginTop:logoMarginTopSm});
		$(".navbar-logo svg").height(logoHeightSm);


		// $("#sog-banner").css({'position':'static'});
		// $("body").css({'padding-top':60});
	
	}else if($(window).scrollTop() >= navbarLg - navbarSm){
		
		// $("#sog-banner").css({'position':'fixed','top':navbarSm});
		// $("body").css({'padding-top':600});

		$(".navbar-container").height(navbarSm);
		$(".navbar-logo").css({marginTop:logoMarginTopSm});
		$(".navbar-logo svg").height(logoHeightSm);
		$("#nav ul li").css({"font-size":menuFontSm});
		$(".navbar-search").css({"top":searchTopSm});
		
		top = ($('#admin-menu').length > 0)?$("#admin-menu").height():0;
		// $(".navbar").css({"top":top});

		$(".navbar-offcanvas").css({"top":"auto"});
		menuTop = ($('#admin-menu').length > 0)?$(".navbar-container").height()+$("#admin-menu").height():$(".navbar-container").height()+2;
		if($('#admin-menu').length > 0){$("body").css({"margin-top":$("#admin-menu").height()});}
		$("#main-menu-row").css({"top":menuTop});
	
		// $("#main-menu-row").addClass("active");
		$("#main-menu-row").css({"background-color":"rgba(255, 255, 255, 1.0)","border-bottom":"2px solid #E3E3E3"});
	
	}else if(navbarLg - navbarSm > $(window).scrollTop() && $(window).scrollTop() >= 0){

		// $("#sog-banner").css({'position':'static'});
		// $("body").css({'padding-top':140});

		top = ($('#admin-menu').length > 0)?$("#admin-menu").height():0;
		// $(".navbar").css({"top":top});

		var navbar = navbarLg - $(window).scrollTop();
		$(".navbar-container").height(navbar);
		
		$(".navbar-offcanvas").css({"top":"auto"});
		menuTop = ($('#admin-menu').length > 0)?$(".navbar-container").height()+$("#admin-menu").height():$(".navbar-container").height()+0;
		if($('#admin-menu').length > 0){$("body").css({"margin-top":$("#admin-menu").height()});}
		$("#main-menu-row").css({"top":menuTop});
		
		// console.log("menuTop: "+menuTop);

		if($(window).scrollTop() >= endLogoScale){
			$(".navbar-logo").css({marginTop:logoMarginTopSm});
			$(".navbar-logo svg").height(logoHeightSm);
			$("#nav ul li").css({"font-size":menuFontSm});
			$("#main-menu-row").css({"background-color":"rgba(255, 255, 255, 1.0)","border-bottom":"2px solid #E3E3E3"});

		}else if(endLogoScale > $(window).scrollTop() && $(window).scrollTop() > startLogoScale){
			var scrollProgress = (($(window).scrollTop() - startLogoScale)/(endLogoScale-startLogoScale));
			// console.log(scrollProgress);

			var logoMarginTop = logoMarginTopLg - (logoMarginTopLg - logoMarginTopSm)*scrollProgress;
			$(".navbar-logo").css({marginTop:logoMarginTop});
			
			var logo = logoHeightLg - (logoHeightLg - logoHeightSm)*scrollProgress;
			$(".navbar-logo svg").height(logo);
			
			var menuFontSize = menuFontLg - (menuFontLg - menuFontSm)*scrollProgress;
			$("#nav ul li").css({"font-size":menuFontSize});
			
			var searchTopSize = searchTopLg - (searchTopLg - searchTopSm)*scrollProgress;
			$(".navbar-search").css({"top":searchTopSize});

			if($('#sog-banner').length > 0){
				if($('#sog-banner').css('display') == 'none'){scrollProgress = 1.0;}
				$("#main-menu-row").css({"background-color":"rgba(255, 255, 255, "+scrollProgress+")"});
			}else{
				$("#main-menu-row").css({"background-color":"rgba(255, 255, 255, 1.0)","border-bottom":"2px solid #E3E3E3"});
			}

		}else if(startLogoScale >= $(window).scrollTop()){
			$(".navbar-logo").css({marginTop:logoMarginTopLg});
			$(".navbar-logo svg").height(logoHeightLg);
			$("#nav ul li").css({"font-size":menuFontLg});
			$(".navbar-search").css({"top":searchTopLg});

			if($('#sog-banner').length > 0){
				$("#main-menu-row").css({"background-color":"rgba(255, 255, 255, "+(($('#sog-banner').css('display')=='none')?1.0:0.0)+")","border-bottom":(($('#sog-banner').css('display')=='none')?"2px solid #E3E3E3":"none")});
			}else{
				$("#main-menu-row").css({"background-color":"rgba(255, 255, 255, 1.0)","border-bottom":"2px solid #E3E3E3"});
			}
		}
		if(menuFade){
			if($(window).scrollTop() >= endMenuFade){
				$(".navbar-offcanvas").fadeTo(0,0);
			}else if(endMenuFade > $(window).scrollTop() && $(window).scrollTop() >= startMenuFade){
				var menuOpacity = (1 - ( ($(window).scrollTop()-startMenuFade) * (1/(endMenuFade-startMenuFade)) ) );
				$(".navbar-offcanvas").fadeTo(0,menuOpacity);
			}else if(startMenuFade > $(window).scrollTop()){
				$(".navbar-offcanvas").fadeTo(0,1);
			}
		}		
	}
}

function menuControls(){
	// console.log($(window).width());
    var w = $(window).width();
	var tOut;
    $('#nav ul').off().on("mouseover",
        function () {
        	var el = this;
        	// console.log($(el).attr('id'));
        	if(!inArr[$(el).attr('id')]){
				inArr[$(el).attr('id')] = true;
		        setTimeout(function(){
		        	if(inArr[$(el).attr('id')]){
			        	if(w > breakpoint){
				            $(".menu-row").stop(true, true).fadeIn("slow");
				           	$("#sog-banner .layer").stop(true, true).fadeIn("slow");
				           	$("#banner-skip, #banner-controls").fadeOut(200);
			        	}
			        }
		        },200);
		        // console.log(inArr);
		    }
        }
    );
    
    $('#main-menu-row').on("mouseover",
        function () {
    	 	inArr[$(this).attr('id')] = true;
        }
    );

    $('#nav ul').on("mouseout",
        function () {
    	   inArr[$(this).attr('id')] = false;
        	if(w > breakpoint){
			    setTimeout(function(){ 
		          if(!inArr["main-menu"] && !inArr["main-menu-row"] && !inArr["navbar-icon-user"] && !inArr["navbar-icon-search"]){
		        	closeMenu();
		          }
		    	},100);
    		}
    	}
    );

    $("#main-menu-row").on("mouseout",
        function () {
		    inArr[$(this).attr('id')] = false;
		    if(w > breakpoint){
			 	setTimeout(function(){ 
		          if(!inArr["main-menu"] && !inArr["main-menu-row"]){ 
		        	closeMenu();
		        	// $("#main-menu-row").stop(true, true).fadeOut("slow")
		        	// $("#nav ul li").css({"box-shadow":"none"});
		        	// $("#sog-banner .layer").stop(true, true).fadeOut("slow");
		          }

		    	},100);
        	}
        }
    );

	$('#nav ul li').each(function(){
		var replace = $(this).attr('class').replace(/(menu-[0-9]*)/,'');
		var contentId = $(this).attr('class').replace(replace,'');

		$(this).off().on("mouseover",
	        function () {
	        	var w = $(window).width();
	        	if(w > breakpoint){
					$("#nav ul li").css({"box-shadow":"none"});
			        $(this).css({"box-shadow":"inset 0 -2px 0 0px #AAAAAA"});
			        if(inArr.active != contentId){
			        	$("#main-menu-row .container").fadeOut(200,function(){
			        		$("#main-menu-row>.container>ul>li").css({"display":"none"});
			        		$("#"+contentId).css({"display":"inline"});
			        		$("#main-menu-row .container").fadeIn(200);
			        	});
			        	inArr.active = contentId;
			        }
			    }else{
			    }
			    
			 //    setTimeout(function(){ 
				// 	if(!inArr[$("#nav ul").attr('id')]){
				// 	    $("#banner-skip, #banner-controls").fadeOut(400);
				// 	}
				// },200);
			
			    // console.log(contentId);
	    	}
	    );
	});
    // $("#main-menu-row").css({"display":"inline"});
    // $("#main-menu-row").css({"background-color":"rgba(255, 255, 255, 1.0)","border-bottom":"2px solid #E3E3E3"});
    // $("#main-menu-row>div>ul>li:nth-child(8)").css({"display":"inline"});
}

function closeMenu(){

	if(inArr.active !== null){
		// console.log("closeMenu");
		inArr.active = null;
		$("#nav ul li").css({"box-shadow":"none"});
		$(function () {
			$("#main-menu-row").stop(true, true).fadeOut(400);
			$("#main-menu-row").removeClass("scrollActive");
			$("#main-menu-row>.container>ul>li").css({"display":"none"});
			$("#sog-banner .layer").stop(true, true).fadeOut(400);
			$("#banner-skip, #banner-controls").fadeIn(400);
			// console.log("closeMenu");
		});
	}
}

function activeTabs(){
	$('#tabNav a').click(function (e) {
	  e.preventDefault();
	  $(this).tab('show');
	});
}

function localStorageQuery (regex,action,el,attr,value) {
  var i, results = [];
  for (i in localStorage) {
  	// console.log('i: '+i);
    if ( localStorage.hasOwnProperty(i) ) {
      if ( i.match(regex) ) {
        if(action === 'delete'){
        	localStorage.removeItem(i);
        }else{
	        value = JSON.parse(localStorage.getItem(i));
	        results.push({key:i,val:value});
	    }
      }
    }
  }
  // console.log(results);
  return results;
}

function addThemeFormElements(){
	$('label').css({'display':'block'});
	$('input[type="text"],input[type="email"],input[type="phone"],input[type="number"],textarea').addClass('form-control');
	$('input[type="submit"]').addClass('btn btn-default');
	$('.field.webform').find('select').addClass('form-control');
	$('.field.webform').find('input[type="submit"]').addClass('form-control');
	$('.webform-container-inline').removeClass('webform-container-inline').find('div').css({'display':'inline'});
	$('.webform-calendar').hide();
	$('select').addClass('selectpicker');
	$('.selectpicker').selectpicker();
}

function hideDetails(){
	$('.expand-all.off').off().on('click',function(){
		$('.hide_details').find('.body').slideDown();
		$(this).addClass('on');
		$(this).removeClass('off');
		hideDetails();
	});

	$('.expand-all.on').off().on('click',function(){
		$('.hide_details').find('.body').slideUp();
		$(this).addClass('off');
		$(this).removeClass('on');
		hideDetails();
	});

	$('.hide_details').each(function(){
		$(this).find('.title').off().on('click',function(faq){
			$(this).parent().find('.body').slideToggle();
			// faq.find('.body').css({'border':'1px solid orange'});
		});
	});
}

function socialShareDisable(){
	$('.social').find('.share').find('li').find('a').off()
	.on('click.myDisable', function(e) { e.preventDefault(); })
	.on('click',function(){
		if($('.alert.alert-danger').length === 0)$('.row.entityContent').prepend('<div class="alert alert-danger" role="alert"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Social sharing is currently disabled.</div>');
		
		var scroll = $('.alert.alert-danger').offset().top-$('.navbar').height()-10;
		$('html,body').animate({scrollTop: scroll},600);	
		
	});
}

function lightboxOptions(){
	// if($('.lb-image').length === 0)return;
	
	console.log($('.lb-image').attr('src'));
	if($('.lb-outerContainer').find('iframe').length === 0){
		$('.lb-outerContainer').prepend("<iframe src='https://sog.adobeconnect.com/website-tour' style='overflow:hidden;width:1100px;height:850px;border:0;'/></iframe>");
	}
	$('.lb-image').addClass('hide');
	$('.lb-details').addClass('hide');
	$('.lb-closeContainer').css({'margin':'75px -65px 0 0'});
	$('.lb-nav').remove();
}

function msieversion() {

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    // if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // If Internet Explorer, return version number
        // alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
    // else                 // If another browser, return 0
        // alert('otherbrowser');

   return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))?true:false;
}
















