var viewCtrl = function($scope, $filter, $timeout, ViewData, urlService, $location, $analytics) {
	var _this = this;
	_this.urlService = urlService;
	var page = _this.urlService.getPage();
	var reload = true;

	$scope.page = page;

	$scope.viewData = new ViewData();
	
	$scope.filter = {};

	if(expandTiles){
		$scope.$watch(
			function () { return $('.tile').not('.tile.expand').length; },
			function (newValue, oldValue) {
			  if (newValue !== oldValue) {
			    // code goes here
			    // console.log('added more tiles');
			    // $.when()
			    // .then($scope.viewData.expandTile())
			    // .then($scope.$apply());
			    $scope.viewData.expandTile();
			  }
			}
		);
	}

	$scope.onChange = function (){
		$scope.viewData.done = false;
		
		//get path and api urls
		var uri = _this.urlService.generateUrls($scope);
		
		//fire next page with data api corresponding to selected filters
		$scope.viewData.nextPage(uri.api);

		//update location.href
		$location.path(uri.path);

		//report new path to google analytics
		var url = '/'+$location.absUrl().split('/').splice(3).join('/');
		$analytics.pageTrack(url);
	};

	$scope.nextPage = function (){
		var uri = _this.urlService.generateUrls($scope);
		$scope.viewData.nextPage(uri.api);

	};

	$scope.sort = function (value){
		if($('#view').attr('data-title') === 'Courses' && value === 'field_offering_date_value') $scope.filter.content_type = 'course_offering';
		if(value === 'field_keywords'){
			$scope.filter = [];
			// $scope.filter.length = 0;
			$scope.display_option('list');
			setTimeout(function(){$('.selectpicker').selectpicker('refresh');},100);
		}
		$scope.filter.sort_by = value;
		$scope.onChange();
		setTimeout(function(){$('.selectpicker').selectpicker('refresh');},100);
	};
	
	$scope.order = function (value){
		$scope.filter.sort_order = value;
		$scope.onChange();
		setTimeout(function(){$('.selectpicker').selectpicker('refresh');},100);
	};

	var fetchDataDelay = 500;   // milliseconds
    var fetchDataTimer;

    $scope.keyUp = function(){
    	var page = _this.urlService.getPage();
    	var searchStr = localStorage.getItem(page+'.filter.search');
    	if($scope.filter.search === '' && searchStr !== null)$scope.onSearch();
    };

    $scope.onSearch = function (){
    	// console.log('$scope.search: '+$scope.filter.search);
    	// $('.sort_options,.display_options').addClass('hidden');
    	$scope.filter.sort_by = ($scope.filter.search !== '')?'score':$('#view').attr('data-sort-by');
    	$scope.filter.sort_order = ($scope.filter.search !== '')?'desc':$('#view').attr('data-sort-order');
    	
    	// console.log('$scope.filter.sort_by: '+$scope.filter.sort_by+'\n$scope.filter.sort_order: '+$scope.filter.sort_order);
        
        $timeout.cancel(fetchDataTimer);
        fetchDataTimer = $timeout(function () {
      		$scope.onChange();
        }, fetchDataDelay);
    };

	$scope.display_option = function(type){
		if($scope.filter.sort_by === 'field_keywords' && type !== 'list') return;
		$scope.display = type;
		localStorage.setItem(page+'.display',$scope.display);
	};

	$scope.display_image = function(){
		$scope.image = ($scope.image===false)?true:false;
		localStorage.setItem(page+'.image',$scope.image);
	};

	$scope.keyUpFactor = function(key){
		// console.log(key);
		var val = parseInt($scope.viewData.testVars[key].factor);
		val = (isNaN(val))?0:val;
		$scope.viewData.testVars[key].factor = val;
		$scope.viewData.tally();
		
		var page = _this.urlService.getPage();
		localStorage.setItem(page+'.test.'+key,val);
	};

	$scope.displayRules = false;
	$scope.displayRules = (localStorage.getItem(page+'.displayRules'))?true:false;
	
	$scope.display_rules = function(key){
		if($scope.displayRules === false){
			$scope.displayRules = true;
			localStorage.setItem(page+'.displayRules',1);
		}else{
			$scope.displayRules = false;
			localStorage.removeItem(page+'.displayRules');
		}
	};

	$scope.join_with_or = function(){
		var page = _this.urlService.getPage();
		if($scope.viewData.joinWithOr === false){
			$scope.viewData.joinWithOr = true;
			localStorage.setItem(page+'.joinWithOr',1);
		}else{
			$scope.viewData.joinWithOr = false;
			localStorage.removeItem(page+'.joinWithOr');
		}
		// console.log($scope.viewData.joinWithOr+' '+ localStorage.getItem(page+'.filter.search'));
		if(localStorage.getItem(page+'.filter.search')) $scope.onSearch(page);
	};

	$scope.resetFilters = function(){
		$scope.filter.length = 0;
		$scope.filter = [];
		$scope.filter.sort_by = $('#view').attr('data-sort-by');
		$scope.filter.sort_order = $('#view').attr('data-sort-order');
		$scope.display = (localStorage.getItem(page+'.display'))?localStorage.getItem(page+'.display'):(($('#view').attr('data-display').length>0)?$('#view').attr('data-display'):"tile");
		$scope.image = (localStorage.getItem(page+'.image'))?((localStorage.getItem(page+'.image')==='true')?true:false):(($('#view').attr('data-image') === 'false')?false:true);
	};
	
	$scope.pathVarsToScope = function(){
		$scope.resetFilters();
		var filter = [];
      	var ct = $filter('content_types');

      	var path = $location.path().split('/');

		for(var i=0;i<path.length;i++){

			if(path[i] === '')continue;

			if(path[i] === 'public-officials' && $.isNumeric(path[i+1])){
			  key = 'roles';
			  value = path[i+1];
			  i+=2;
			}else if(path[i] === 'topics' && $.isNumeric(path[i+1])){
			  key = 'topics';
			  value = path[i+1];
			  i+=2;
			}else if(path[i] === 'types' && $.isNumeric(path[i+1])){
			  key = 'field_profile_types';
			  value = path[i+1];
			  i+=2;
			 }else if(path[i] === 'groups' && $.isNumeric(path[i+1])){
			  key = 'field_group';
			  value = path[i+1];
			  i+=2;
			}else if(path[i] === 'authors' && $.isNumeric(path[i+1])){
			  key = 'field_author';
			  value = path[i+1];
			  i+=2;
			}else if(path[i] === 'blogs' && $.isNumeric(path[i+1])){
			  key = 'field_blog_reference';
			  value = path[i+1];
			  i+=2;
			}else if(path[i] === 'search' && typeof path[i+1] !== 'undefined'){
			  key = 'search';
			  value = path[i+1];
			  i+=1;
			}else if(path[i] === 'by' && typeof path[i+1] !== 'undefined'){
			  key = 'sort_by';
			  value = path[i+1];
			  i+=1;
			  if(value === 'first-name') value = 'field_first_name_value_1';
			  if(value === 'offering-date') value = 'field_offering_date_value';
			  if(value === 'last-updated') value = 'changed';
			  if(value === 'published') value = 'created';
			  if(value === 'fields-of-expertise') value = 'field_keywords';
			  if($.inArray(value, $scope.options[key]) === -1) value = $('#view').attr('data-sort-by');
			}else if(path[i] === 'order' && typeof path[i+1] !== 'undefined'){
			  key = 'sort_order';
			  value = path[i+1];
			  i+=1;
			  if($.inArray(value, $scope.options[key]) === -1) value = $('#view').attr('data-sort-order');
			}else if(path[i] === 'featured' && typeof path[i+1] !== 'undefined'){
			  // default
			  // if(path[i+1] === 'new-publications') $scope.viewData.subset.active = 'New Publications';
			  if(path[i+1] === 'forthcoming-titles') $scope.viewData.subset.active = 'Forthcoming Titles';
			  if(path[i+1] === 'updates-and-supplements') $scope.viewData.subset.active = 'Updates and Supplements';
			  i+=1;
			  continue;
			}else{
			  var c = ct(path[i],{'input':'path','output':'machine_name'});
			  if(typeof c !== 'undefined'){
			    key = 'content_type';
			    value = c;
			  }
			}
			// console.log(key+' => '+value);
			if(key && value) $scope.filter[key] = value;
		}
		
		if($scope.filter.sort_by === 'field_keywords') $scope.display_option('list');

		setTimeout(function(){$('.selectpicker').selectpicker('refresh');},100);
	};

	$scope.resetFilters();

	$scope.$on('$locationChangeSuccess', function () {
		// Close large drop down nav menu 
		if (typeof closeMenu == 'function') closeMenu();
		
		// Catch path changes from nav menu	which do not reload page due to AngularJS
		$scope.pathVarsToScope();

		// $scope.nextPage();
		$scope.onChange();
	});	

	$(document).ready(function(){
		
		$scope.pathVarsToScope();
		if($('#view').attr('data-path') === '/publications' && $location.path().indexOf('/featured/') === -1){
		    setTimeout(function(){
		    	$("html, body").animate({ scrollTop: $('#searchPublications').offset().top - navbarSm - 4 }, 600);
		    },500);
		}
		// if(expandTiles){
	 // 		$(window).on('popstate', function(e) {
	 // 			e.preventDefault();
		// 		// console.log('Back button was pressed.');
		// 		// console.log(history.state);
		// 		this.back = true;
		// 		// console.log(history.state);
		// 		// console.log(history.state.url+' === '+$("#view").attr("data-path"));
		// 		if(history.state.url === $("#view").attr("data-path")){
		// 			console.log('close');
		// 			$('.tile.expand').find('.closeBtn').find('svg').trigger("click");
		// 		}else{
		// 			console.log('open');
		// 	      	$("[data-path='"+history.state.url+"']").trigger("click");
		// 	    }
		//     });
		// }
	});

	
};

app.controller('ViewController', viewCtrl)
.config(function($locationProvider){
	$locationProvider.hashPrefix('!');
	//Did not use html5Mode(true) because node url's would match <base>
	//This would work if you implement expandTile to bring node content into collection page in the form of an expanded tile
	//$locationProvider.html5Mode(true).hashPrefix('!');
});

app.factory('ViewData', function($http, $location, infoService, urlService) {
	// localStorage.set('')
	var apiOrig,pageNum;
	this.urlService = urlService;
	var page = this.urlService.getPage();
	
	var ViewData = function() {

		this.api = '';
		this.items = [];
		this.results = 0;
		this.busy = false;
		this.done = false;
		this.pageNum = 0;
		this.infoService = infoService;
		this.template = false;
		this.back = false;
		this.search = false;
		this.total = 0;
		this.joinWithOr = (localStorage.getItem(page+'.joinWithOr'))?true:false;

		this.subset = [];
		this.subset.items = [];
		this.subset.busy = false;
		this.subset.position = 0;
		this.subset.prevActive = false;
		this.subset.nextActive = false;

		this.testVars = {
			// 'legal_summary': 			{'title':'Legal Summary','factor':3},
			'microsite_landing_page': 	{'title':'Microsite Landing Page','factor':3},
			'basic_page': 				{'title':'Basic Page','factor':2},
			'book': 					{'title':'Book','factor':2},
			'course': 					{'title':'Course','factor':2},
			'blog': 					{'title':'Blog','factor':2},
			'blog_post': 				{'title':'Blog Post','factor':2},
			'bulletin': 				{'title':'Bulletin','factor':2},
			'other': 					{'title':'Other *','factor':1}
		};

		angular.forEach(this.testVars,function(value,key){
			if(localStorage.getItem(page+'.test.'+key))value.factor = localStorage.getItem(page+'.test.'+key);
		});
	};

	ViewData.prototype.tally = function() {
		var tally=0;
		for (var i=0; i<this.items.length; i++) {
			 tally += (this.testVars[this.items[i].type_key])?this.testVars[this.items[i].type_key].factor*(10-i):this.testVars.other.factor*(10-i);
		}
		this.total = tally;
	};

	ViewData.prototype.expandTile = function() {
		var _this = this;
		// console.log('apply expand');
	    var tilesPerRow = 0;
	    var row,pos,place,end;
		
		// console.log('tiles: '+$('.tile').length);

		$('.tile').each(function(){
			if($(this).prev().length > 0 && $(this).position().top != $(this).prev().position().top)return false;
	        tilesPerRow++;
		});
		
		// console.log('tilesPerRow: '+tilesPerRow);
		// if(tilesPerRow === 0)return;

		$('.tile').not('.faculty-and-staff').not('.tile.expand').find('a').on('click.myDisable', function(e) { e.preventDefault(); });
		// $('a').off('click.myDisable');

		$('.tile').not('.faculty-and-staff').off().on('click',function(){
			var tile = this;

			// $('.tile.expand').slideUp(400,function(){
				$('.tile.expand').remove();
				// console.log("close done");
			// });
		
			pos = $('.tile').index(tile)+1;
			row = Math.ceil(pos/tilesPerRow);
			end = row*tilesPerRow; 

			var lastTile = ($('.tile:nth-child('+end+')').length > 0)?$('.tile:nth-child('+end+')'):$('.tile:nth-child('+$('.tile').length+')');
			lastTile.after('<div class="col-xs-12 tile expand"><div class="wrapper" ng-include="viewData.template.url"><div class="loading"><img src="/sites/www.sog.unc.edu/themes/sog/dist/images/loading-02.gif"/></div></div></div>');

			var scroll = $(tile).offset().top + $(tile).height() + parseInt($(tile).css('margin-bottom')) - (($(".navbar").height() > 90)?90:$(".navbar").height());
			$("html, body").animate({ scrollTop: scroll}, 'slow');

			$('.tile.expand').slideDown('slow',function(){
				var url = '/expand-tile/'+$(tile).attr('data-nid');
				var path = $(tile).attr('data-path');
				
				// _this.template = url;
				_this.infoService.getNode(url).then(function(data){

					var el = $('.tile.expand').find('.wrapper');
					// var curHeight = el.height();
					el.html(data.data).css('height', 'auto');
					// console.log(path);
					
					console.log('tabs: '+$('#tabs-menu').length);
					if($('#tabs-menu').length > 0){
						$('#tabs-menu').tabCollapse({
							tabsClass: 'hidden-sm hidden-xs',
					    	accordionClass: 'visible-sm visible-xs'
						});
					}

					if(!this.back){
						if (typeof (history.pushState) != "undefined") {
				            var obj = { page: $(tile).attr('data-title'), url: $(tile).attr('data-path') };
				            history.pushState(obj, obj.page, obj.url);
				            // console.log('save: '+obj.url);
				        } else {
				            console.log("Browser does not support HTML5.");
				        }
				    }else{
				    	// console.log('BACK');
				    }
				    this.back = false;
					// $location.path(path);//angular path change


					hideDetails();
					socialShareDisable();
					
					// $("html, body").animate({ scrollTop: $(".tile.expand").offset().top - (($(".navbar").height() > 90)?90:$(".navbar").height()) }, 'slow');

					$('.tile.expand').find('.closeBtn').find('svg').off().on('click',function(){//.css({'cursor':'pointer'})

						$("html, body").animate({ scrollTop: $(tile).offset().top-$(".navbar").height()-20}, 'slow');
						$('.tile.expand').slideUp('slow',function(){
							$('.tile.expand').remove();
							
							if(!this.back){
								if (typeof (history.pushState) != "undefined") {
						            var obj = { page: $('#view').attr('data-title'), url: $('#view').attr('data-path') };
						            history.pushState(obj, obj.page, obj.url);
						            // console.log('save: '+obj.url);
						        } else {
						            console.log("Browser does not support HTML5.");
						        }
						    }else{
						    	// console.log('BACK');
						    }
						});


					});
				},function(){
					// console.log('fail');
					location.href = path;
				});
			});
		});

		$('.tile').not('.faculty-and-staff').css({'cursor':'pointer'});
	};

	ViewData.prototype.subsetActive = function(option){
		// var page = this.urlService.getPage();
		this.subset.active = option;
		var currentPath = $location.path();
		var path = currentPath.split('/');
		var value;

		// default
		// if(option === 'New Publications') value = 'new-publications';
		if(option === 'Forthcoming Titles') value = 'forthcoming-titles';
		if(option === 'Updates and Supplements') value = 'updates-and-supplements';
		var pos = $.inArray('featured', path);
		if(option === 'New Publications'){
			if(pos > -1) path.splice(pos,2);
			$location.path(path.join('/'));
		}else if(pos > -1){
			path[pos+1] = value;
			$location.path(path.join('/'));
		}else{
			$location.path(currentPath+'/featured/'+value);
		}


		localStorage.setItem(page+'.filter.subset',option);
		this.subset.position = 0;
		$('.marquee').find('.window').animate({
          scrollLeft: 0
        }, 500,'swing');

		var count = 0;
		angular.forEach(this.subset.items, function(item){
		    count += item.field_publication_subset.indexOf(option) !== -1 ? 1 : 0;
		});

		this.subset.activeTally = count;
	};

	ViewData.prototype.subsetOver = function() {
		// console.log(this.subset.activeTally);
		if(this.subset.activeTally <= 1) return;
		var el = $('.marquee').find('.window');
		var pos = parseInt(el.width()/el.find('.tile').width());
		
		if(this.subset.position > 0){
			$('.marquee').find('.prev').stop().fadeTo(200,1.0);
			this.subset.prevActive = true;
		}

		if(this.subset.position < this.subset.activeTally-pos){
			$('.marquee').find('.next').stop().fadeTo(200,1.0);
			this.subset.nextActive = true;
		}
	};

	ViewData.prototype.subsetOut = function() {
		$('.marquee').find('.prev, .next').stop().fadeTo(200,0.0);
	};

	ViewData.prototype.subsetPrev = function() {
		if(this.subset.position === 0) return;
		var el = $('.marquee').find('.window>.wrapper');
		l = $('.marquee').find('.window').scrollLeft();
		// w = el.find('.tile').width()+30;
		w = $('.marquee').find('.window').width();
		if(msieversion())w -= 30;
		s = l-w;
		if(l<w)s = 0;
		// console.log(s);
		$('.marquee').find('.window').animate({
          scrollLeft: s
        }, 500,'swing');

        var pos = parseInt(w/el.find('.tile').width());
        this.subset.position -= pos;
        if(this.subset.position <= 0){
        	$('.marquee').find('.prev').stop().fadeTo(200,0);
	        this.subset.prevActive = false;
	    }

        if(this.subset.position < this.subset.activeTally-1){
        	$('.marquee').find('.next').stop().fadeTo(200,1.0);
	        this.subset.nextActive = true;
	    }
	};

	ViewData.prototype.subsetNext = function() {
		if(this.subset.position === this.subset.activeTally-1) return;
		var el = $('.marquee').find('.window>.wrapper');
		l = $('.marquee').find('.window').scrollLeft();
		// w = el.find('.tile').width()+30;
		w = $('.marquee').find('.window').width();
		
		if(msieversion())w -= 30;
		s = l+w;
		if( s>el.width() )s = el.width();
		// console.log(s);
		$('.marquee').find('.window').animate({
          scrollLeft: s
        }, 500,'swing');
        
        var pos = parseInt(w/el.find('.tile').width());
        this.subset.position += pos;
        if(this.subset.position > 0){
        	$('.marquee').find('.prev').stop().fadeTo(200,1.0);
	        this.subset.prevActive = true;
	    }

        if(this.subset.position >= this.subset.activeTally-2){
        	$('.marquee').find('.next').stop().fadeTo(200,0);
	        this.subset.nextActive = false;
	    }
	};

	ViewData.prototype.nextPage = function(api) {
		
		if(typeof api === 'undefined'){console.log('no api url');return;}

		if (this.busy || this.done) return;

		var limit = parseInt($('#tile_limit').attr('data-limit'));

		var itemsPerPage = (page === 'search-test')?10:12;
		
		if(api.data === this.api){
			pageNum = parseInt(this.items.length/itemsPerPage);
			if(this.pageNum === pageNum)return;
			this.pageNum = pageNum;
		}else{
			this.pageNum = 0;
			this.items.length = 0;
		}
		
		this.api = api.data;
		
		this.busy = true;

		this.searchStr = ( localStorage.getItem(page+'.filter.search') )?1:0;
		
		api.data += ((api.slice(-1) === '/')?'?':'&')+"page="+this.pageNum;


		var m = api.data.match(/\/search(-test){0,1}\//g);
		if(m !== 'undefined' && m !== null && api.data.indexOf('keys=') === -1){
			this.items.length = 0;
			this.busy = false;
		}else{
			if(page === 'search-test' && this.joinWithOr === true)api = api.replace(/\+/g,'+OR+');
			

			if(api.subset && this.subset.items.length === 0){
				this.subset.busy = true;
				this.subset.active = (localStorage.getItem(page+'.filter.subset'))?localStorage.getItem(page+'.filter.subset'):$('#subset').attr('data-active');

				// console.log(api.subset);
				$http.get(api.subset).success(function(data) {
				  var items = data;
				  if(this.subset.items.length > 0)return;
				  for (var i = 0; i < items.length; i++) {
				    items[i].image = (items[i].field_image_gallery && items[i].field_image_gallery !== '')?items[i].field_image_gallery:items[i].image;
					items[i].image = (items[i].field_microsite_banner && items[i].field_microsite_banner !== '')?items[i].field_microsite_banner:items[i].image;
					items[i].image = (items[i].field_cover_image_1 && items[i].field_cover_image_1 !== '')?items[i].field_cover_image_1:items[i].image;
					items[i].image = (items[i].field_cover_image && items[i].field_cover_image !== '')?items[i].field_cover_image:items[i].image;
					items[i].image = (items[i].field_image && items[i].field_image !== '')?items[i].field_image:items[i].image;					
					items[i].image = (items[i].image)?items[i].image.replace(/\/files\//g,'/files/styles/large/public/'):'';

					items[i].type_key = items[i].type.replace(/\s/g,"_").toLowerCase();
					items[i].field_publication_subset = items[i].field_publication_subset.split(', ');
				    this.subset.items.push(items[i]);
				  }
				  this.subset.busy = false;
				  this.subsetActive(this.subset.active);

				}.bind(this)).error(function(){
					// $('#subset').html('<h1>This site is experiencing a technical issue</h1><div>The information for this page has failed to load.</div>');
				});
			}
			
			if(api.results && this.pageNum === 0){
				// console.log(api.results);
				$http.get(api.results).success(function(data) {
					// console.log(data);
					this.results = (data.length > 1)?data.length:((data.length === 1)?data[0].nid:0);
				}.bind(this)).error(function(){
					// $('#view').html('<h1>This site is experiencing a technical issue</h1><div>The information for this page has failed to load.</div>');
				});
			}
			
			// console.log(api.data);
			$http.get(api.data).success(function(data) {
			  var items = data;

			  for (var i = 0; i < items.length; i++) {
			    items[i].image = (items[i].field_image_gallery && items[i].field_image_gallery !== '')?items[i].field_image_gallery:items[i].image;
				items[i].image = (items[i].field_microsite_banner && items[i].field_microsite_banner !== '')?items[i].field_microsite_banner:items[i].image;
				items[i].image = (items[i].field_cover_image_1 && items[i].field_cover_image_1 !== '')?items[i].field_cover_image_1:items[i].image;
				items[i].image = (items[i].field_cover_image && items[i].field_cover_image !== '')?items[i].field_cover_image:items[i].image;
				items[i].image = (items[i].field_image && items[i].field_image !== '')?items[i].field_image:items[i].image;

				items[i].image = (items[i].image)?items[i].image.replace(/\/files\//g,'/files/styles/large/public/'):'';
				if(items[i].type_key)items[i].type_key = items[i].type.replace(/\s/g,"_").toLowerCase();
			    // this.total += parseInt(items[i].test);
			    this.items.push(items[i]);
			    // if(i+1 === itemsPerPage)break;
			    if(page === 'search-test' && i+1 === itemsPerPage){
			    	this.done = true;
			    	break;
			    }
			  }
			  this.tally();
			  this.busy = false;

			  if(items.length < itemsPerPage) this.done = true;

			  //end infinitePage for keywords
			  if(items.length > itemsPerPage) this.done = true;

			  if (limit !== 0 && this.items.length >= limit) this.done = true;

			}.bind(this)).error(function(){
				$('#view').html('<h1>This site is experiencing a technical issue</h1><div>The information for this page has failed to load.</div>');
			});

		}
		$("#content-wrap").css({"height":"auto"});
	};

	return ViewData;
});
