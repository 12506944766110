var nodeCtrl = function($scope, $location, $timeout, $anchorScroll, nodeDataFactory, urlService, $analytics) {
	var tabs = [];
	var _this = this;
	_this.urlService = urlService;

	$('[name="search"]').each(function(){
		tabs[tabs.length] = $(this).attr('data-base');
	});

	$scope.node = new nodeDataFactory(tabs);

	// this.infoService = infoService;
	// this.init();
	
	var nid = $("#node").attr("data-nid");
	if(nid === "")return;
	
	$scope.node.getInfo(nid);

	$scope.onChange = function (base){
		$scope.reloadTab = base;
		_this.urlService.updateFilterValues($scope);
	};

	var fetchDataDelay = 500;   // milliseconds
    var fetchDataTimer;

    $scope.onSearch = function (base){
        $timeout.cancel(fetchDataTimer);
        fetchDataTimer = $timeout(function () {
      		$scope.onChange(base);
        }, fetchDataDelay);
    };

    $scope.keyUp = function(base){
    	var page = _this.urlService.getPage();
    	// console.log(page+'.'+base+'.filter.search');
    	var searchStr = localStorage.getItem(page+'.'+base+'.filter.search');
    	if($scope[ base ].filter.search === '' && searchStr !== null)$scope.onSearch(base);
    };

	$scope.display = "list";
	$scope.image = ($('#view').attr('data-image') === 'false')?false:true;

	$scope.display_option = function(type){
		$scope.display = type;
	};

	$scope.display_image = function(){
		$scope.image = ($scope.image===false)?true:false;
	};

	$scope.updateTab = function(tab){
		$scope.tab = (!tab)?'':tab;
		$location.path(tab);

		//report new path to google analytics
		var url = '/'+$location.absUrl().split('/').splice(3).join('/');
		$analytics.pageTrack(url);
	};

	$scope.pathVarsToScope = function(){
    	var path = $location.path().split('/');
		$scope.tab = (path[1])?path[1]:'';
	};

	$scope.pathVarsToScope();

	$(document).ready(function(){

		for(i=0;i<tabs.length;i++){
			$scope[tabs[i]] = [];
			$scope[tabs[i]].filter = [];
		}

		$.when($scope.node.filters())
		 .pipe($('.selectpicker').selectpicker())
		 .done(_this.urlService.loadFilterValues());

		
		var hash = $location.hash();
		if(hash && jQuery('#'+hash).length > 0){
		    setTimeout(function(){
		    	jQuery("html, body").animate({ scrollTop: jQuery('#'+hash).offset().top - navbarSm - 4 }, 600);
		    },1000);
		}

	});

};

app.controller('NodeController', nodeCtrl)
.config(function($locationProvider){
	$locationProvider.hashPrefix('!');
// 	$locationProvider.html5Mode(true).hashPrefix('!');
});

app.factory('nodeDataFactory', function($http, infoService, urlService) {
	var nodeDataFactory = function(tabs) {
		this.infoService = infoService;
		this.urlService = urlService;
		// this.tabs = tabs;

	};

	nodeDataFactory.prototype.filters = function() {
		var _this = this;
		var options = [];
		var base,o;
		if($('select[data-base]').length > 0){
			$.each($('select[data-base]'),function(){
				base = $(this).attr('data-base');
				_this[base] = [];
				_this[base].filter = [];
				_this[base].filter[$(this).attr('data-field').replace(/\[\]/g,'')] = [];

				// _this[base].filter[$(this).attr('data-field').replace(/\[\]/g,'')].push('bkc');
		
				options = $(this).attr('data-options').split(',');
				for(i=0;i<options.length;i++){
					_this[base].filter[$(this).attr('data-field').replace(/\[\]/g,'')].push(options[i]);
				}
				// console.log('add filterData: '+base+'.filter.'+$(this).attr('data-field').replace(/\[\]/g,''));
				// console.log(_this[base].filter[$(this).attr('data-field').replace(/\[\]/g,'')]);
			});
		}
	};

	nodeDataFactory.prototype.reloadView = function(field){
		if($('.field[data-field="'+field+'"]').length > 0){
			var filter,filters,p,base,str_fields;
			var _this = this;
			var page = _this.urlService.getPage();
			var f = [];

			$.each($('.field[data-field="'+field+'"]'),function(){
				base = field;//$(this).attr('data-base');
				filters = $(this).attr('data-filter').split(',');

				if($(this).find(".filter input[type=text]").length > 0){
					$.each($(this).find(".filter input[type=text]"),function(){
						str_fields = $(this).attr('data-fields').split(',');
					});
				}

				for(i=0;i<filters.length;i++){
					field = filters[i].split('=')[0].replace(/\[\]/g,'');
					field = ($.inArray(field,str_fields) > -1)?'search':field;
					key = page+((base)?'.'+base:'')+'.filter.'+field;
					storedVal = localStorage.getItem(key);
					// console.log(name+'='+storedVal+'in array? '+$.inArray(name+'='+storedVal,f));
					if(storedVal){
						if($.inArray(filters[i].split('=')[0]+'='+storedVal,f) > -1)continue;
						f[f.length] = filters[i].split('=')[0]+'='+storedVal.replace(/\s/g,'+');
					}else{
						f[f.length] = filters[i].replace(/\s/g,'+');
					}
				}

				filter = $(this).attr('data-base')+'/all/?'+f.join('&');

				// 2015.08.20 join all search strings with OR per Todd's request 
				filter = filter.replace(/\+/g,'+OR+');

				params = {'field':$(this).attr('data-field')};
				// console.log($(this).attr('data-base')+'Reload');

				_this[$(this).attr('data-base')+'Reload'].busy = true;
				
				_this.infoService.getInfo(filter,params)
				.then(function(data){
					// console.log(data);
					_this[data.config.field] = data.data;
					_this[data.config.field+'Reload'].busy = false;
				});
			});
		}
	};

	nodeDataFactory.prototype.getInfo = function(id) {
		var tab,filters,filter,params,key,base,name,f,str_fields,storedVal,field;
		var _this = this;
		var page = _this.urlService.getPage();

		if($("#node").attr("data-type") == 'profile'){
		
			// _this.infoService.getProfile(id)
			// .then(function(data){
			// 	console.log(data.data[0]);
			// 	_this.profile = data.data[0];
			// });

			if($('#node').find('.field[data-base]').length > 0){
				$.each($('#node').find('.field[data-base]'),function(){
					// if($(this).attr('data-base') === ''){console.log($(this).html());return;}
					if($(this).attr('data-filter') === ''){console.log('View requires data-filter.');return;}
					// console.log('data-filter: '+$(this).attr('data-filter'));

					base = $(this).attr('data-base');

					filters = $(this).attr('data-filter').split(',');
					f = [];

					if($(this).find(".filter input[type=text]").length > 0){
						$.each($(this).find(".filter input[type=text]"),function(){
							str_fields = $(this).attr('data-fields').split(',');
						});
					}

					for(i=0;i<filters.length;i++){
						field = filters[i].split('=')[0].replace(/\[\]/g,'');
						field = ($.inArray(field,str_fields) > -1)?'search':field;
						key = page+((base)?'.'+base:'')+'.filter.'+field;
						storedVal = localStorage.getItem(key);
						
						if(storedVal){
							if($.inArray(filters[i].split('=')[0]+'='+storedVal,f) > -1)continue;
							f[f.length] = filters[i].split('=')[0]+'='+storedVal.replace(/\s/g,'+');
						}else{
							f[f.length] = filters[i].replace(/\s/g,'+');
						}
					}

					filter = $(this).attr('data-base')+(($.inArray($(this).attr('data-base'),['courses','publications','blogs','resources']) > -1)?'/all':'')+'/?'+f.join('&');
					
					// 2015.08.20 join all search strings with OR per Todd's request 
					filter = filter.replace(/\+/g,'+OR+');

					params = {'field':$(this).attr('data-field')};
					// console.log(params);
					_this[base+'Reload'] = [];
					_this[base+'Reload'].busy = true;
					// _this[$(this).attr('data-field')+'Reload'].busy = true;

					_this.infoService.getInfo(filter,params)
					.then(function(data){
						// console.log(data);
						_this[data.config.field] = data.data;
						_this[data.config.field+'Reload'].busy = false;
					});				
				});
			}
		}
	};
	
	return nodeDataFactory;
});